export default {
    colors: {
      primaryBackground: '#1C2340',
      primaryBackgroundRGB: '28,35,64',
  
      secondaryBackground: '#151934',
      secondaryBackgroundRGB: '21,25,52',
  
      tertiaryBackground: '#32395E',
      tertiaryBackgroundRGB: '50,57,94',
  
      secondaryContrast: '#FFAE00',
      secondaryContrastRGB: '255,174,0',
  
      primaryContrast: '#F154AF',
      primaryContrastRGB: '241,84,175',
  
      secondaryText: '#F7C3DC',
      secondaryTextRGB: '247,195,220',
  
      tertiaryContrast: '#C770CF',
      tertiaryContrastRGB: '199,112,207',
  
      disableText: '#7C5B7E',
      disableTextRGB: '124,91,126',
  
      primaryText: '#FFFFFF',
      primaryTextRGB: '255,255,255',
  
      shadow: '#000000',
      shadowRGB: '0,0,0',
  
  
      facebookButton: '#3B5998',
      googleButton: '#DA4D39',
    }
  }