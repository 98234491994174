import React from 'react'

import { Container, Content, CloseButton, CloseIcon } from './styles'

function Modal(props) {

  return (
    <Container
      isOpen={props.showModal}
      onRequestClose={() => props.closeModal(false)}
      shouldCloseOnEsc={true}
    >
      <CloseButton onClick={() => props.closeModal(false)}>
        <CloseIcon />
      </CloseButton>

      <Content>

        {props.children}

      </Content>

    </Container>
  )
}

export default Modal