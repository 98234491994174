

const screenNames = (location) => {

  switch (location.pathname) {
    case "/":
      return "Home"
    case "/scheduling_add":
      return "Agendar"
    case "/scheduling_list":
      return "Meus agendamentos"
    default:
      return "Home"
  }

}

export default screenNames