import React, { createContext, useState, useEffect } from 'react'

import api from '../services/api'

export const BusinessContext = createContext({})

function BusinessProvider({ children }) {

  const [business, setBusiness] = useState(JSON.parse(localStorage.getItem("business")) || {})  

  useEffect(() => {
    localStorage.setItem('business', JSON.stringify(business))
  }, [business])

  const getBusiness = async (businessId) => {

    try {

      const response = await api.get(`businesses/${businessId}`)

      setBusiness(response.data)

      localStorage.setItem("business", JSON.stringify(response.data));

    } catch (error) {
      console.log(error.message)
    }

  }


  return (
    <BusinessContext.Provider value={{ business, getBusiness }}>
      {children}
    </BusinessContext.Provider>
  )
}

export default BusinessProvider
