import styled from 'styled-components'

import HashLoader from "react-spinners/HashLoader"

export const Container = styled.div` 
  width: 100%;
  height: 100%;
  padding: 12px;
  display: flex;
  justify-content: center; 
`;

export const Spinner = styled(HashLoader).attrs(({ theme }) => ({
  color: theme.colors.secondaryText
}))`

`
