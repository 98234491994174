import styled from 'styled-components'

import Modal from 'react-modal'

import { FaWindowClose } from "react-icons/fa"

export const Container = styled.div`
width: 100%;
height: 100vh;
background-color: ${({ theme }) => theme.colors.tertiaryBackground};
align-items: center;    
padding: 0;
margin: 0;
`;

export const ContentContainer = styled.div`
  width: 100%;  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.colors.tertiaryBackground};
  padding-top: 16px;  
`

export const Logo = styled.div`
  width: 50vw;
  height: 45vw; 
  //background-color: rgba(${({ theme }) => theme.colors.primaryTextRGB}, 0.7);
  background-image: url(${({ url }) => url});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-origin: content-box;  
  border-radius: 6px;
  margin-bottom: 32px;
  `

export const HoursListContainer = styled.div`
  width: 90%;    
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;    
`

export const HourContainer = styled.button`
  width: 75px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.secondaryText};
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.shadow}; 
  display: flex;
  text-align: center;
  justify-content: center;
  border: none;
`

// MODAL

export const WrapModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ModalContainer = styled(Modal)`  
width: 80%;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
outline: none;
`;

export const ModalContent = styled.div`   
  background-color: ${({ theme }) => theme.colors.secondaryText};   
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
`;

export const CloseButton = styled.button`
  width: 100%;
  border: none;
  display: flex;
  justify-content: start;
  background-color: ${({ theme }) => theme.colors.secondaryText};
  border-bottom: 0.1px solid rgba(${({ theme }) => theme.colors.secondaryBackgroundRGB}, 0.4);
`

export const CloseIcon = styled(FaWindowClose).attrs(({ theme }) => ({
  color: theme.colors.tertiaryBackground,
  size: 50,
}))`
  margin: 16px;   
`;

export const ModalTitle = styled.p`
  text-align: center;
  margin: 12px;
`
// RADIO BUTTON

export const RadioButtonContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const RadioButton = styled.input`
  border: 1px solid red;
  margin: 8px;
`
export const RadioButtonText = styled.p`
  margin-bottom: 0;
`




