export const cpfAndCnpjMask = (cpfOrCnpj) => {

  cpfOrCnpj = cpfOrCnpj.toString().replace(/\D/g, "")      //Remove tudo o que não é dígito

  if (cpfOrCnpj.length >= 1 && cpfOrCnpj.length <= 11) {
    cpfOrCnpj = cpfOrCnpj.replace(/(\d{3})(\d)/, "$1.$2")  //Coloca um ponto entre o 3º e o 4º dígitos
    cpfOrCnpj = cpfOrCnpj.replace(/(\d{3})(\d)/, "$1.$2")  //Coloca um ponto entre o 3º e o 4º dígitos
    //de novo (para o segundo bloco de números)
    cpfOrCnpj = cpfOrCnpj.replace(/(\d{3})(\d{1,2})$/, "$1-$2") //Coloca um hífen entre o terceiro e o quarto dígitos

  } else if (cpfOrCnpj.length > 11) {

    cpfOrCnpj = cpfOrCnpj.replace(/^(\d{2})(\d)/, "$1.$2")             //Coloca ponto entre o 2º e o 3º dígitos
    cpfOrCnpj = cpfOrCnpj.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3") //Coloca ponto entre o 5º e o 6º dígitos
    cpfOrCnpj = cpfOrCnpj.replace(/\.(\d{3})(\d)/, ".$1/$2")           //Coloca uma barra entre o 8º e o 9º dígitos
    cpfOrCnpj = cpfOrCnpj.replace(/(\d{4})(\d)/, "$1-$2")
    cpfOrCnpj = cpfOrCnpj.slice(0, 18)
  }

  return cpfOrCnpj

}

export const cpfMask = (cpf) => {

  cpf = cpf.toString().replace(/\D/g, "")      //Remove tudo o que não é dígito

  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")  //Coloca um ponto entre o 3º e o 4º dígitos
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")  //Coloca um ponto entre o 3º e o 4º dígitos
  //de novo (para o segundo bloco de números)
  cpf = cpf.replace(/(\d{3})(\d{1,2})/, "$1-$2") //Coloca um hífen entre o terceiro e o quarto dígitos

  cpf = cpf.slice(0, 14)


  return cpf

}

export const phoneMask = (phone) => {

  phone = phone.toString().replace(/\D/g, "")

  phone = phone.replace(/^(\d{1,2})(\d)/, "($1) $2")
  phone = phone.replace(/(\d{5})(\d)/, "$1-$2")
  phone = phone.slice(0, 15)

  return phone
}

export const cepMask = (cep) => {

  cep = cep.toString().replace(/\D/g, "")

  cep = cep.replace(/(\d{5})(\d)/, "$1-$2")
  cep = cep.slice(0, 9)

  return cep
}

export const brazilianCurrencyMask = (value) => {

  value = value && value.toString().replace(/\D/g, "")

  value = value.replace(/(\d)(\d{17})$/, "$1.$2")
  value = value.replace(/(\d)(\d{14})$/, "$1.$2")
  value = value.replace(/(\d)(\d{11})$/, "$1.$2")
  value = value.replace(/(\d)(\d{8})$/, "$1.$2")
  value = value.replace(/(\d)(\d{5})$/, "$1.$2")
  value = value.replace(/(\d)(\d{1,2})$/, "$1,$2")

  if (!value) {
    return value
  } else {
    return 'R$ ' + value
  }

}

export const percentageMask = (value) => {

  value = value.toString().replace(/\D/g, "")  
  
  value = value.slice(0, 2)

  return value 

}


