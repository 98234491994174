import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Modal from 'react-modal'

import api from '../../services/api'

import { BusinessContext } from '../../contexts/business'
import { CustomerContext } from '../../contexts/customer'
import { SchedulingContext } from '../../contexts/scheduling'

import Header from '../../components/Header'
import InputSelect from '../../components/InputSelect'
import InputDatePicker from '../../components/InputDatePicker'
import InputText from '../../components/InputText'
import ButtonSubmit from '../../components/ButtonSubmit'
import Loading from '../../components/Loading'

import { phoneMask } from '../../utils/inputMasks'
import { toBrazilianCurrency, toUSACurrency } from '../../utils/currency'

import {
  Container,
  ContentContainer,
  Logo,
  HoursListContainer,
  HourContainer,
  ModalContainer,
  ModalContent,
  CloseButton,
  CloseIcon,
  WrapModal,
  ModalTitle,
  RadioButton,
  RadioButtonContainer,
  RadioButtonText
} from './styles'

Modal.setAppElement('#root')

function SchedulingAdd() {

  const { business } = useContext(BusinessContext)
  const { customer, getCustomer, registerCustomer } = useContext(CustomerContext)
  const { registerScheduling } = useContext(SchedulingContext)
  const navigate = useNavigate()

  const services = business?.Services
  //const serviceName = services.map(x => `${x.name} - R$ ${toBrazilianCurrency(x.price)}`) 
  const serviceName = services.map(x => x.name)

  const [serviceSelectedName, setServiceSelectedName] = useState("")
  const [serviceSelected, setServiceSelected] = useState({})
  const [employeeSelected, setEmployeeSelected] = useState("")
  const [employeeNames, setEmployeeNames] = useState("")
  const [employees, setEmployees] = useState("")
  const [date, setDate] = useState(new Date(Date.now() + ( 3600 * 1000 * 24)))
  const [showModal, setShowModal] = useState(false)
  const [showRegisterModal, setShowRegisterModal] = useState(false)
  const [dataType, setDataType] = useState("E-mail")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [hours, setHours] = useState([])
  const [loading, setLoading] = useState(false)

  //console.log(business)

  useEffect(() => {

    if (serviceSelectedName) {

      const service = services.find((service) => service?.name === serviceSelectedName)
      const employeeList = service?.EmployeeServices?.map((EmployeeService) => EmployeeService?.Employee)
      const employeeNames = service?.EmployeeServices?.map((EmployeeService) => EmployeeService?.Employee.name)

      setEmployees(employeeList)
      setEmployeeNames(employeeNames)
      setServiceSelected(service)
    }

  }, [serviceSelectedName])


  useEffect(() => {

    const getAvailableTimes = async () => {

      try {

        const day = date.getDate()
        const month = date.getMonth()
        const year = date.getFullYear()
        const stringDate = `${year}-${month + 1}-${day}`
        let employee = {}

        if (employees.length > 0) {
          employee = employees.find(x => x.name === employeeSelected)
        }

        if (!customer?.id) {

          setShowModal(true)

        } else {

          setShowModal(false)

          if (customer?.id && employee?.id && business?.id && serviceSelected.id) {

            setLoading(true)

            const response = await api.post(`customers/${customer?.id}/schedulings/hours_available/employees/${employee?.id}/businesses/${business?.id}?date=${stringDate}`,
              {
                services: [{ service_id: serviceSelected?.id }]
              }
            )

            if (response.data) {
              setHours(response.data)
            }

            setLoading(false)

          }

        }

      } catch (error) {
        setLoading(false)
        console.log(error.message)
      }

    }

    getAvailableTimes()

  }, [employeeSelected, date, customer])


  function onChangeValue(event) {
    setDataType(event.target.value)
    //console.log(event.target.value)
  }

  const handlePhone = (event) => {
    const phoneMaskData = phoneMask(event.target.value)
    setPhone(phoneMaskData)
  }

  const getCustomerModal = () => {

    return (
      <ModalContainer
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        shouldCloseOnEsc={true}
      >
        <CloseButton onClick={() => setShowModal(false)}>
          <CloseIcon />
        </CloseButton>
        

        <ModalContent>

          <ModalTitle>Por favor informe o seu telefone ou email</ModalTitle>

          <RadioButtonContainer onChange={onChangeValue}>
            <RadioButton type="radio" value="E-mail" checked={dataType === "E-mail"} readOnly /><RadioButtonText>E-mail</RadioButtonText>
            <RadioButton type="radio" value="Celular" checked={dataType === "Celular"} readOnly /><RadioButtonText>Celular</RadioButtonText>
          </RadioButtonContainer>

          {dataType === "E-mail" ?

            <InputText
              iconName="FaEnvelope"
              placeholder="exemplo@exemplo.com"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              keyboardType="email-address"
            />
            :
            <InputText
              iconName="FaMobileAlt"
              placeholder="(99) 99999-9999"
              value={phone}
              onChange={(phone) => handlePhone(phone)}
              type="tel"
              inputmode="decimal"
            />
          }

          <ButtonSubmit message="Enviar" onClick={handleSubmitGetCustomer} />

        </ModalContent>
      </ModalContainer>
    )
  }

  const registerCustomerModal = () => {

    return (
      <ModalContainer
        isOpen={showRegisterModal}
        onRequestClose={() => setShowRegisterModal(false)}
        shouldCloseOnEsc={true}
      >
        <CloseButton onClick={() => setShowRegisterModal(false)}>
          <CloseIcon />
        </CloseButton>

        <ModalContent>

          <ModalTitle>Não encontramos seu cadastro. Por favor informe seus dados</ModalTitle>

          <InputText
            iconName="FaAddressBook"
            placeholder="Nome*"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />

          <InputText
            iconName="FaEnvelope"
            placeholder="exemplo@exemplo.com"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            keyboardType="email-address"
          />

          <InputText
            iconName="FaMobileAlt"
            placeholder="(99) 99999-9999"
            value={phone}
            onChange={(phone) => handlePhone(phone)}
            type="tel"
            inputmode="decimal"
          />

          <ModalTitle>* Campos Obrigatórios</ModalTitle>

          <ButtonSubmit message="Enviar" onClick={handleSubmitRegisterCustomer} />

        </ModalContent>
      </ModalContainer>
    )
  }

  const handleSubmitGetCustomer = async () => {

    let data = {}
    let numberPhone = phone?.replace(/[^0-9]/g, '') || null

    email ? data.email = email : data.email = ""
    numberPhone ? data.phone = numberPhone : data.phone = ""

    try {

      if (data?.email || data?.phone) {

        const customerExists = await getCustomer(data)

        setShowModal(false)

        if (!customerExists) {
          setShowRegisterModal(true)
        }

      } else {
        alert("Você deve digitar um E-mail ou um número de celular")
      }

    } catch (error) {
      console.log(error.message)
    }
  }

  const handleSubmitRegisterCustomer = async () => {

    let data = {}
    let numberPhone = phone?.replace(/[^0-9]/g, '') || null

    email ? data.email = email : data.email = ""
    numberPhone ? data.phone = numberPhone : data.phone = ""

    if (name) {
      data.name = name
    } else {
      alert("Você precisa digitar um nome")
      return
    }

    if (!data?.email && !data?.phone) {
      alert("Você deve digitar um E-mail ou um número de celular")
      return
    }

    try {

      await registerCustomer(data, business?.id)

      setShowRegisterModal(false)

    } catch (error) {
      alert(error.message)
      console.log(error.message)

    }



  }

  const handleSchedulingSubmit = async (hour) => {

    let data = {}
    const employee = employees.find(x => x.name === employeeSelected)
    const cost = toBrazilianCurrency(serviceSelected?.price)
    const newDate = new Date(date)
    const day = newDate.getDate()
    const month = newDate.getMonth()
    const year = newDate.getFullYear()
    const dateBr = `${year}-${month + 1}-${day}`

    data.body = {
      start_time: hour,
      date: dateBr,
      cost: toUSACurrency(cost),
      services: [
        {
          service_id: serviceSelected?.id
        }
      ]
    }

    data.employee_id = employee?.id
    data.business_id = business?.id
    data.customer_id = customer?.id

    try {

      await registerScheduling(data)

      setShowRegisterModal(false)

      navigate('/')

     // alert("Agendamento realizado com sucesso!")

    } catch (error) {
      console.log(error.message)
    }

  }

  return (
    <>
      <Container className='scheduling_add'>
        <Header />

        <ContentContainer>
          <Logo url={`${business?.url}`} />

          <InputSelect
            placeholder={serviceSelectedName || "Selecione um serviço"}
            dataList={serviceName}
            selected={(data) => setServiceSelectedName(data)}
          />

          {serviceSelectedName &&
            (
              <>
                <InputSelect
                  placeholder={employeeSelected || "Selecione um profissional"}
                  dataList={employeeNames}
                  selected={(data) => setEmployeeSelected(data)}
                />

                <InputDatePicker date={(date) => setDate(date)} />
              </>
            )
          }

          {loading ? <Loading /> : (

            <HoursListContainer>

              {
                hours && hours.map((hour, index) => {
                  return (
                    <HourContainer
                      key={index}
                      onClick={() => handleSchedulingSubmit(hour)}>{hour}</HourContainer>
                  )
                })
              }

            </HoursListContainer>
          )

          }

        </ContentContainer>

        <WrapModal>
          {
            showModal && getCustomerModal()
          }
        </WrapModal>

        <WrapModal>
          {
            showRegisterModal && registerCustomerModal()
          }
        </WrapModal>

      </Container>


    </>
  )
}

export default SchedulingAdd


