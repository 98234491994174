import React, { useState, useEffect } from 'react'

import DatePicker, { registerLocale } from "react-datepicker"

import "react-datepicker/dist/react-datepicker.css"
import { ptBR } from "date-fns/locale"

import { Container, DatePickerContainer } from './styles'

registerLocale("ptBR", ptBR)


function InputDatePicker(props) {

  const [startDate, setStartDate] = useState(new Date(Date.now() + ( 3600 * 1000 * 24)))
  const language = "ptBR"


  useEffect(() => {    
    props.date(startDate)
  }, [startDate])

  return (
    <Container>
      <DatePickerContainer
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        dateFormat="dd/MM/yyyy"
        locale={language}
      />
    </Container>
  )
}


export default InputDatePicker