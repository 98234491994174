import React from 'react'

import getOS from '../../utils/getOS'

import {
  Container,
  ImageContainer,
  Logo,
  InputContainer,
  Input,
  EmailIcon,
  PasswordIcon,
  SubmitButton,
  Link,
  Title,
  GoogleButton,
  GoogleIcon,
  GoogleTitle
} from './styles'

function Login() {

 // console.log(getOS())

  return (
    <ImageContainer>
      <Container>
        <Logo />

        <InputContainer>
          <EmailIcon />
          <Input placeholder='E-mail'/>
        </InputContainer>

        <InputContainer>
          <PasswordIcon />
          <Input placeholder='Senha' type="password" />
        </InputContainer>

      <SubmitButton>Entrar</SubmitButton>

      <Link>Esqueci a senha</Link>
      <Link>Nova conta</Link>
      
      <Title>Ou entre com a conta google:</Title>

      <GoogleButton>
        <GoogleIcon />
        <GoogleTitle>Google</GoogleTitle>
      </GoogleButton>
      </Container>
    </ImageContainer>
  )
}

export default Login