import styled from 'styled-components'

import Modal from 'react-modal'

import { FaWindowClose } from "react-icons/fa"

export const Container = styled(Modal)`  
width: 80%;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
outline: none;
`;

export const Content = styled.div`   
  background-color: ${({ theme }) => theme.colors.secondaryText};   
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
`;

export const CloseButton = styled.button`
  width: 100%;
  border: none;
  display: flex;
  justify-content: start;
  background-color: ${({ theme }) => theme.colors.secondaryText};
  border-bottom: 0.1px solid rgba(${({ theme }) => theme.colors.secondaryBackgroundRGB}, 0.4);
`

export const CloseIcon = styled(FaWindowClose).attrs(({ theme }) => ({
  color: theme.colors.tertiaryBackground,
  size: 50,
}))`
  margin: 16px;   
`;