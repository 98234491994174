import React, { useEffect, useContext, useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'

import { BusinessContext } from '../../contexts/business'

import Header from '../../components/Header'

import {
  Container,
  Logo,
  ContentContainer,
  ActionsContainer,
  ActionButton,
  ActionButtonTitle,
  SchedulingIcon,
  SchedulingsIcon
} from './styles'

function BusinessData() {

  const { getBusiness, business } = useContext(BusinessContext)
  let [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const businessId = searchParams.get('business_id')

  useEffect(() => {
    if (businessId) {
      getBusiness(businessId)
    }
  }, [])

  useEffect(() => {
    // console.log(business.url)

  if(business?.url) { 
     let link = document.querySelector("link[rel~='icon']")
    if (!link) {
      link = document.createElement('link')
      link.rel = 'icon'
      document.getElementsByTagName('head')[0].appendChild(link)
    }
    link.href = business?.url
  }


  }, [business])


  // console.log(business)

  return (
    <Container className='container-fluid'>
      <Header />

      <ContentContainer>

        <Logo url={`${business?.url}`} />

        <ActionsContainer>
          <ActionButton onClick={() => navigate('/scheduling_add')} >
            <SchedulingIcon />
            <ActionButtonTitle>Agendar</ActionButtonTitle>
          </ActionButton>

          <ActionButton onClick={() => navigate('/scheduling_list')}>
            <SchedulingsIcon />
            <ActionButtonTitle>Agendamentos</ActionButtonTitle>
          </ActionButton>

        </ActionsContainer>

      </ContentContainer>

    </Container>
  )
}

export default BusinessData