import React from 'react'


import { Container, Spinner } from './styles'

function Loading({ ...rest }) {
  return (
    <Container className="sweet-loading">
      <Spinner {...rest} />
    </Container>
  )
}

export default Loading