
import React, { useContext } from 'react'

import { createBrowserRouter, RouterProvider, createRoutesFromElements, Route } from "react-router-dom"
import { ThemeProvider } from "styled-components"
import themes from './styles/themes/index'

import BusinessData from "./pages/BusinessData"
import SchedulingAdd from "./pages/SchedulingAdd"
import SchedulingList from "./pages/SchedulingList"
import Login from './pages/Login'

// const ClientRoute = ({ children }) => {
//   const { isAuthenticated, loggedUser, isLoading } = useContext(AuthContext);

//   if (isLoading) {
//     return <section>Carregando...</section>;
//   }

//   if (isAuthenticated || loggedUser?.type == "Cliente") {
//     return children || <Outlet />;
//   } else {
//     return <Navigate to='/login' replace />;
//   }
// }



const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<BusinessData />} />
      <Route path="/scheduling_add" element={<SchedulingAdd />} />
      <Route path="/scheduling_list" element={<SchedulingList />} />
    </Route>
  )
);

function Routes() {
  return (
    <ThemeProvider theme={themes.femaleTheme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  )
}

export default Routes