import styled from 'styled-components'

import {
  FaRegCalendarAlt,
  FaRegClock,
  FaCogs,
  FaUserTie,
  FaRegBookmark,
  FaRegTrashAlt,
  FaCheck
} from "react-icons/fa"


export const Container = styled.div`
width: 100%;
height: 100vh;
background-color: ${({ theme }) => theme.colors.tertiaryBackground};
align-items: center;      
border: 1px red;
padding: 0;
margin: 0;
overflow: none;
`;

export const ContentContainer = styled.div`
  width: 100%;  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.colors.tertiaryBackground};
  padding-top: 16px;   
`

export const Logo = styled.div`
  width: 50vw;
  height: 45vw; 
  //background-color: rgba(${({ theme }) => theme.colors.primaryTextRGB}, 0.7);
  background-image: url(${({ url }) => url});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-origin: content-box;  
  border-radius: 6px;
  margin-bottom: 32px;
  `

export const SchedulingContentContainer = styled.div`
  width: 100%;  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.colors.tertiaryBackground};
  padding-top: 16px;
  overflow: scroll;    
`

export const SchedulingContainer = styled.div`
    width: 90%;
    height: 115px;
    background-color: ${({ theme }) => theme.colors.secondaryBackground};
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    margin-bottom: 8px;
  `

export const SchedulingLeftContainer = styled.div`
  flex: 2;
  position:relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding-top: 8px;
  padding-bottom: 8px;

  &::after {
    content: '';
    position: absolute;
    border-right: 2px solid ${({ theme }) => theme.colors.primaryText};
    height: 80%;
    top: 10%;   
    right: 0;
}
`

export const SchedulingRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 6;  
  padding-left: 12px;
`


export const SchedulingSimpleTitle = styled.p`
    font-size: 14px;
    color: ${({ theme, isColorfull }) => isColorfull ? theme.colors.secondaryText : theme.colors.primaryText};
    margin: 0;
  `

export const SchedulingBigTitle = styled.p`
    font-size: 16px;
    font-weight: bold;
    color: ${({ theme, isColorfull }) => isColorfull ? theme.colors.secondaryText : theme.colors.primaryText};
    margin: 0;
  `

// MODAL

export const WrapModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ModalTitle = styled.p`
  text-align: left;
  margin: 0; 
`
export const ModalContent = styled.div`
  width: 90%;
  margin-bottom: 16px;
`
export const TitleModalContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 12px;
`

export const ModalBigTitle = styled.p`
    font-size: 18px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.secondaryBackground};
    margin: 0;
    margin-top: 16px;
    margin-bottom: 16px;
    text-align: center;
  `

export const CalendarIcon = styled(FaRegCalendarAlt).attrs(({ theme }) => ({
  color: theme.colors.secondaryBackground,
  size: 20,
}))` 
margin: 0;
margin-right: 12px;
`

export const ClockIcon = styled(FaRegClock).attrs(({ theme }) => ({
  color: theme.colors.secondaryBackground,
  size: 20,
}))` 
margin: 0;
margin-right: 12px;
`

export const ServiceIcon = styled(FaCogs).attrs(({ theme }) => ({
  color: theme.colors.secondaryBackground,
  size: 20,
}))` 
margin: 0;
margin-right: 12px;
`

export const EmployeeIcon = styled(FaUserTie).attrs(({ theme }) => ({
  color: theme.colors.secondaryBackground,
  size: 20,
}))` 
margin: 0;
margin-right: 12px;
`

export const StatusIcon = styled(FaRegBookmark).attrs(({ theme }) => ({
  color: theme.colors.secondaryBackground,
  size: 20,
}))` 
margin: 0;
margin-right: 12px;
`

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 32px;
`

// ACTION BUTTON

export const ActionButton = styled.div`
  width: 80px;
  height: 80px;
  background-color: ${({ theme, cancel }) => cancel ? theme.colors.disableText : theme.colors.primaryContrast};
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:active {
    width: 85px;
    height: 85px;
    background-color: ${({ theme, cancel }) => cancel ? `rgba(${theme.colors.disableTextRGB}, 0.8)` : `rgba(${theme.colors.primaryContrastRGB}, 0.8)`};
  }
`

export const ActionButtonText = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.colors.primaryText};
  font-size: 14px;
  margin-top: 4px;
`

export const DeleteIcon = styled(FaRegTrashAlt).attrs(({ theme }) => ({
  color: theme.colors.primaryText,
  size: 18,
}))` 
margin: 0;
`

export const ConfirmIcon = styled(FaCheck).attrs(({ theme }) => ({
  color: theme.colors.primaryText,
  size: 18,
}))` 
margin: 0;
`