import styled from 'styled-components'

export const Container = styled.div`
  width: 100vw;
  height: 70px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.disableText};
  background-color: ${({ theme }) => theme.colors.primaryBackground};
`;

export const Row = styled.div`
  height: 100%;  
`

export const Col = styled.div`
//border: 1px solid red;

&:nth-child(1) {
  border-right: 1px solid ${({ theme }) => theme.colors.disableText}; 
}

padding: 0;
`

export const TitlePage = styled.p`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.primaryText};
  font-weight: bold;    
  margin: 0;
`