import styled from 'styled-components'

import DatePicker from "react-datepicker"

export const Container = styled.div`
  width: 85%;
  height: 50px;     
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(${({ theme }) => theme.colors.tertiaryBackgroundRGB}, 0.6);
  border: 0.1px solid rgba(${({ theme }) => theme.colors.secondaryTextRGB}, 0.7);
  border-radius: 3px;
  color: ${({ theme }) => theme.colors.secondaryText};
  font-size: 14px;
`;


export const DatePickerContainer = styled(DatePicker)`
  width: 100%;
  height: 50px;
  background-color: transparent;
  margin:0;
  padding:0;
  border: none; 
  padding-left: 16px;
  color: ${({ theme }) => theme.colors.secondaryText};
`;
