
export const toBrazilianCurrency = (value) => {
  //value = value.toFixed(2)
  value = (value / 100).toFixed(2)
  const valueString = value.toString()
  let finalValue = ''

  if(valueString.split(".")[1]) {
    finalValue = valueString.split(".")[0]+','+valueString.split(".")[1].padEnd(2, '0')
  } else if (valueString.toString().split(".")[0]) {
    if(parseInt(valueString) < 10) {
      finalValue = valueString.split(".")[0].padStart(2, '0')+','+'00'
    } else {
      finalValue = valueString+','+'00'
    }    
  }

  return finalValue
}

export const toUSACurrency = (value) => {

  const valueString = value.toString().replace('R$ ','')
  let finalValue = ''

  if(valueString.split(",")[1]) {
    finalValue = valueString.split(",")[0]+'.'+valueString.split(",")[1].padEnd(2, '0')
  } else if (valueString.toString().split(",")[0]) {
    if(parseInt(valueString) < 10) {
      finalValue = valueString.split(",")[0].padStart(2, '0')+'.'+'00'
    } else {
      finalValue = valueString+'.'+'00'
    }    
  }

  return finalValue
}

