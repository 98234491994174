import styled from 'styled-components'
import { FaBars } from 'react-icons/fa'

export const Container = styled.div`
    
`;

export const ButtonMenu = styled.button`
  
`;

export const ListMenu = styled.ul`
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.primaryContrast};  
`;

export const ItemMenu = styled.li`
  height: 60px;
  color: ${({ theme }) => theme.colors.primaryText};  
  font-weight: bold;
  font-size: 20px;  
  border-bottom: 1px solid rgba(${({theme})=> theme.colors.shadowRGB}, 0.2);  
`

export const ItemLinkMenu = styled.a`
  font-weight: bold;
  font-size: 20px;  
  color: ${({ theme }) => theme.colors.primaryText};
`

export const Icon = styled(FaBars).attrs(({ theme }) => ({
  color: theme.colors.primaryContrast,
  size: 30
}))``;

