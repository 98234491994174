import React from 'react'

import { Container } from './styles'

function ButtonSubmit({message, ...rest}) {
  return (
    <Container type="button" {...rest}>{message}</Container>
  )
}

export default ButtonSubmit