import React, { createContext, useState, useEffect, useContext } from 'react'

import { BusinessContext } from './business'

import api from '../services/api'

import { toBrazilianCurrency } from '../utils/currency'

export const SchedulingContext = createContext({})

function SchedulingProvider({ children }) {

  const {business} = useContext(BusinessContext)

  const [schedulings, setSchedulings] = useState(JSON.parse(localStorage.getItem("schedulings")) || {})

  useEffect(() => {
    localStorage.setItem('schedulings', JSON.stringify(schedulings))
  }, [schedulings])

  const getSchedulings = async (data) => {

    try {

      const response = await api.get(`customers/${data?.customer_id}/schedulings/businesses/${data?.business_id}`)

      setSchedulings(response.data)

      return response?.data.length > 0 ? true : false

    } catch (error) {
      console.log(error.message)
      return false
    }

  }

  const registerScheduling = async (data) => {

    const { business_id, employee_id, customer_id, body } = data

    try {

      const newDate = new Date(data.body.date)
      const day = newDate.getDate()
      const month = newDate.getMonth()
      const year = newDate.getFullYear()
      const dateBr = `${day}/${month + 1}/${year}`
      const price = toBrazilianCurrency(data.body.cost * 100)

      const schedulingData = `Confirmar agendamento para ${dateBr} às ${data?.body?.start_time} com o custo de R$ ${price}?`

      if (window.confirm(schedulingData)) {

        const response = await api.post(`/schedulings/customers_local/${customer_id}/employees/${employee_id}/businesses/${business_id}`,
          body)

        if (response.data) {

          alert("Agendamento realizado com sucesso!")
        }
      }

    } catch (error) {
      alert(error.message)
      console.log(error.message)
    }

  }

  const deleteScheduling = async (data) => {

    const content = {
      business_id: business.id,
      customer_id: data.customer_id
    }

    try {

      if (window.confirm("Tem certeza que deseja excluir o agendamento?")) {

        const response = await api.delete(`/customers/${data.customer_id}/schedulings/${data.scheduling_id}`)

        if (response.data) {

          await getSchedulings(content)

          alert("Agendamento excluído com sucesso!")
        }
      }

    } catch (error) {
      console.log(error.message)
    }
  }

  const updateScheduling = async (data) => {

    try {

      const response = await api.put(`/customers/${data.customer_id}/schedulings/${data.scheduling_id}/businesses/${data.business_id}`,
        data.body
      )

      if (response.data) {
        getSchedulings(data)
      }

    } catch (error) {
      console.log(error.message)
    }
  }

  return (
    <SchedulingContext.Provider
      value={{ schedulings, getSchedulings, registerScheduling, deleteScheduling, updateScheduling }}
    >
      {children}
    </SchedulingContext.Provider>
  )
}

export default SchedulingProvider