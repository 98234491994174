import styled from 'styled-components'

import { FaPlusCircle, FaCalendarDay } from "react-icons/fa"

export const Container = styled.div`
width: 100%;
height: 100vh;
background-color: ${({ theme }) => theme.colors.tertiaryBackground};
align-items: center;      
border: 1px red;
padding: 0;
margin: 0;
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.tertiaryBackground};
  padding-top: 16px;
`

export const Logo = styled.div`
  width: 50vw;
  height: 45vw; 
  //background-color: rgba(${({ theme }) => theme.colors.primaryTextRGB}, 0.7);
  background-image: url(${({ url }) => url});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-origin: content-box;  
  border-radius: 6px;
  margin-bottom: 32px;    
`

export const ActionsContainer = styled.div`
  width: 100%; 
  height: 70%;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  padding-top: 32px;
`

export const ActionButton = styled.button`
  width: 40%;
  height: 130px;
  padding: 8px;
  background-color: ${({ theme }) => theme.colors.secondaryText};
  border-radius: 6px; 
  border: none;
`

export const ActionButtonTitle = styled.p`
  margin:0;
  margin-top: 8px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.tertiaryBackground};
`

export const SchedulingIcon = styled(FaPlusCircle).attrs(({ theme }) => ({
  color: theme.colors.tertiaryBackground,
  size: 28,
}))``;


export const SchedulingsIcon = styled(FaCalendarDay).attrs(({ theme }) => ({
  color: theme.colors.tertiaryBackground,
  size: 28,
}))``;