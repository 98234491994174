export default {

    colors: {
  
      primaryBackground: '#1C2340',
      primaryBackgroundRGB: '28,35,64',
  
      secondaryBackground: '#151934',
      secondaryBackgroundRGB: '21,25,52',
  
      tertiaryBackground: '#32395E',
      tertiaryBackgroundRGB: '50,57,94',
  
      secondaryContrast: '#FFAE00',
      secondaryContrastRGB: '255,174,0',
  
      primaryContrast: '#3663FD',
      primaryContrastRGB: '54, 99, 253',
  
      secondaryText: '#BCCADA',
      secondaryTextRGB: '188, 202, 218',
  
      tertiaryContrast: '#FD3636',
      tertiaryContrastRGB: '253, 54, 54',
  
      disableText: '#676B7C',
      disableTextRGB: '103, 107, 124',
  
      primaryText: '#FFFFFF',
      primaryTextRGB: '255,255,255',
  
      shadow: '#000000',
      shadowRGB: '0,0,0',
  
  
      facebookButton: '#3B5998',
      googleButton: '#DA4D39',
  
    }
  }