import React, {useContext} from 'react'

import { BusinessContext } from '../../contexts/business'

import {
  Container,
  ButtonMenu,
  Icon,
  ListMenu,
  ItemMenu,
  ItemLinkMenu
} from './styles'

function Menu() {  

  let { business } = useContext(BusinessContext)

  // if(!business?.id) {
  //   business = JSON.parse(localStorage.getItem("business"))
  // }

  //console.log(business)

  return (
    <Container className="btn-group">
      <ButtonMenu className="btn d-flex justify-content-end align-items-center" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
        <Icon size={30} />
      </ButtonMenu>

      <ListMenu className="dropdown-menu">

        <ItemMenu className="dropdown-item d-flex align-items-center">
          <ItemLinkMenu className="dropdown-item" href={`/?business_id=${business?.id}`}>Home</ItemLinkMenu>
        </ItemMenu>
        <ItemMenu className="dropdown-item d-flex align-items-center">
          <ItemLinkMenu className="dropdown-item" href='/scheduling_add'>Agendar</ItemLinkMenu>
        </ItemMenu>
        <ItemMenu className="dropdown-item d-flex align-items-center">
          <ItemLinkMenu className="dropdown-item" href='/scheduling_list'>Meus Agendamentos</ItemLinkMenu>
        </ItemMenu>

      </ListMenu>

    </Container>
  )
}

export default Menu