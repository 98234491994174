import Routes from '../src/routes'

import BusinessProvider from './contexts/business'
import CustomerProvider from './contexts/customer'
import Scheduling from './contexts/scheduling'

function App() {
  return (
    <CustomerProvider>
      <BusinessProvider>
        <Scheduling>
          <Routes />
        </Scheduling>
      </BusinessProvider>
    </CustomerProvider>
  );
}

export default App
