import styled from 'styled-components'

  export const Container = styled.button`
  width: 90%;
  height: 50px;
  background-color: ${({ theme }) => theme.colors.primaryContrast};
  border: none;
  border-radius: 25px;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.primaryText};
  font-weight: bold;
  margin-top: 12px;
  margin-bottom: 8px;
`;

