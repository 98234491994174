import React from 'react'
import { useLocation } from 'react-router-dom'

import Menu from '../Menu'
import screenNames from '../../utils/screenNames'

import { Container, Row, Col, TitlePage } from './styles'

function Header(props) {

  const location = useLocation()

  return (
    <Container className="container-fluid">
      <Row className="row">
        <Col className="col-2 d-flex justify-content-center">
          <Menu />
        </Col>
        <Col className="col-10 d-flex align-items-center justify-content-center">
          <TitlePage>
            { screenNames(location) }
          </TitlePage>
        </Col>
      </Row>
    </Container>
  )
}

export default Header