import React, { useState, useEffect } from 'react'

import { useTheme } from 'styled-components'

import CustomIcon from '../CustomIcon'

import {
  Container,
  TInput
} from './styles'


function InputText({iconName, placeholder, ...rest}) { 
  const theme = useTheme()

  return (
    <>
      <Container>
        <CustomIcon name={iconName} color={theme.colors.tertiaryBackground} />
        <TInput placeholder={placeholder} {...rest} />
        </Container>     
    </>


  )
}

export default InputText