
import styled from 'styled-components'

export const Container = styled.div`

  width: 90%;
  height: 50px;
  background-color: transparent;
  border-radius: 4px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 12px;
  margin-bottom: 12px;
  /* border-color: ${({ theme }) => theme.colors.tertiaryBackground} !important;
  border-width: 0.5px;   */
  border: 0.5px solid ${({ theme }) => theme.colors.tertiaryBackground};
`;

export const TInput = styled.input`
  width: 90%;
  height: 100%;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.tertiaryBackground};   
  border: none;
  background-color: transparent;
  padding-left: 12px;
  outline: none;
`

// export const Icon = styled(FontAwesome5).attrs(({ theme }) => ({
//   color: `rgba(${theme.colors.secondaryTextRGB}, 1)`,
//   size: 16
// }))` 
// `