import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { BusinessContext } from '../../contexts/business'
import { CustomerContext } from '../../contexts/customer'
import { SchedulingContext } from '../../contexts/scheduling'

import { getWeekDays, getWeekFullDays } from '../../utils/dates'

import Header from '../../components/Header'
import Modal from '../../components/Modal'

import {
  Container,
  Logo,
  ContentContainer,
  SchedulingContentContainer,
  SchedulingContainer,
  SchedulingLeftContainer,
  SchedulingRightContainer,
  SchedulingSimpleTitle,
  SchedulingBigTitle,
  WrapModal,
  ModalContent,
  TitleModalContainer,
  ModalTitle,
  ModalBigTitle,
  ActionButton,
  CalendarIcon,
  ClockIcon,
  ServiceIcon,
  EmployeeIcon,
  StatusIcon,
  ButtonsContainer,
  DeleteIcon,
  ActionButtonText,
  ConfirmIcon
} from './styles'

function SchedulingList() {

  const navigate = useNavigate()

  const { business } = useContext(BusinessContext)
  const { customer, getCustomer, registerCustomer } = useContext(CustomerContext)
  const { getSchedulings, schedulings, updateScheduling, deleteScheduling } = useContext(SchedulingContext)

  const [showModal, setShowModal] = useState(false)
  const [selectedScheduling, setSelectedScheduling] = useState({})

  useEffect(() => {

    if (customer?.id) {

      let data = {
        business_id: business.id,
        customer_id: customer.id
      }

      getSchedulings(data)

    } else {
      navigate('/scheduling_add')
    }

  }, [])

  const handleScheduling = (scheduling) => {

    setShowModal(true)

    setSelectedScheduling(scheduling)

  }

  const handleSetSchedulingStatus = async () => {

    const body = { status: "Confirmado" }
    let data = {}

    data.body = body
    data.business_id = business.id
    data.customer_id = customer.id
    data.scheduling_id = selectedScheduling.id

    await updateScheduling(data)

    setShowModal(false)   

  }

  const handleDeleteScheduling = async () => {

    let data = {}

    data.customer_id = customer.id
    data.scheduling_id = selectedScheduling.id

    await deleteScheduling(data)

    setShowModal(false)    

  }

  const schedulingDataModal = () => {

    const employeeName = selectedScheduling?.SchedulingEmployeeServices?.reduce((prevVal, x) => {
      if (x.EmployeeService?.Employee?.name) {
        if (prevVal !== "") {
          return prevVal + ' | ' + x.EmployeeService?.Employee?.name
        } else {
          return x.EmployeeService?.Employee?.name
        }

      } else {
        return prevVal
      }
    }, "")

    const servicesName = selectedScheduling?.SchedulingEmployeeServices?.reduce((prevVal, x) => {
      if (x.EmployeeService?.Service?.name) {
        if (prevVal !== "") {
          return prevVal + ' | ' + x.EmployeeService?.Service?.name
        } else {
          return x.EmployeeService?.Service?.name
        }

      } else {
        return prevVal
      }
    }, "")

    const date = new Date(selectedScheduling?.date)
    const now = new Date()
    let alreadyPassed = false

    if (now > date) {
      alreadyPassed = true
    }

    return (
      <ModalContent>
        <ModalBigTitle>SEU AGENDAMENTO</ModalBigTitle>

        <TitleModalContainer>
          <CalendarIcon />
          <ModalTitle>{`${date.getUTCDate()}/${date.getMonth() + 1} ${getWeekFullDays(date.getUTCDay())}`}</ModalTitle>
        </TitleModalContainer>

        <TitleModalContainer>
          <ClockIcon />
          <ModalTitle>Horário {selectedScheduling?.start_time}</ModalTitle>
        </TitleModalContainer>

        <TitleModalContainer>
          <ServiceIcon />
          <ModalTitle>{servicesName}</ModalTitle>
        </TitleModalContainer>

        <TitleModalContainer>
          <EmployeeIcon />
          <ModalTitle>{employeeName}</ModalTitle>
        </TitleModalContainer>

        <TitleModalContainer>
          <StatusIcon />
          <ModalTitle>{selectedScheduling?.status}</ModalTitle>
        </TitleModalContainer>

        {
          !alreadyPassed && (
            <ButtonsContainer>

              <ActionButton cancel onClick={() => handleDeleteScheduling()}>
                <DeleteIcon />
                <ActionButtonText>Cancelar</ActionButtonText>
              </ActionButton>

              <ActionButton onClick={() => handleSetSchedulingStatus()}>
                <ConfirmIcon />
                <ActionButtonText>Confirmar</ActionButtonText>
              </ActionButton>

            </ButtonsContainer>
          )
        }


      </ModalContent>
    )
  }


  return (
    <Container>
      <Header />

      <ContentContainer>
        <Logo url={`${business?.url}`} />

        <SchedulingContentContainer>

          {
            schedulings.length > 0 && schedulings?.map((scheduling, index) => {

              const now = new Date()
              const date = new Date(scheduling?.date)
              let alreadyPassed = false

              if (now > date) {
                alreadyPassed = true
              }

              const employeeName = scheduling?.SchedulingEmployeeServices?.reduce((prevVal, x) => {
                if (x.EmployeeService?.Employee?.name) {
                  if (prevVal !== "") {
                    return prevVal + ' | ' + x.EmployeeService?.Employee?.name
                  } else {
                    return x.EmployeeService?.Employee?.name
                  }

                } else {
                  return prevVal
                }
              }, "")

              const servicesName = scheduling?.SchedulingEmployeeServices?.reduce((prevVal, x) => {
                if (x.EmployeeService?.Service?.name) {
                  if (prevVal !== "") {
                    return prevVal + ' | ' + x.EmployeeService?.Service?.name
                  } else {
                    return x.EmployeeService?.Service?.name
                  }

                } else {
                  return prevVal
                }
              }, "")

              return (

                <SchedulingContainer key={index} onClick={() => handleScheduling(scheduling)}>

                  <SchedulingLeftContainer>
                    <SchedulingSimpleTitle>{getWeekDays(date.getUTCDay())}</SchedulingSimpleTitle>
                    <SchedulingBigTitle>{`${date.getUTCDate()}/${date.getMonth() + 1}`}</SchedulingBigTitle>
                    <SchedulingSimpleTitle>{scheduling?.start_time}</SchedulingSimpleTitle>
                  </SchedulingLeftContainer>

                  <SchedulingRightContainer>
                    <SchedulingBigTitle isColorfull >{servicesName}</SchedulingBigTitle>
                    <SchedulingSimpleTitle isColorfull >{employeeName}</SchedulingSimpleTitle>
                    <SchedulingSimpleTitle isColorfull >{scheduling?.status}</SchedulingSimpleTitle>
                  </SchedulingRightContainer>

                </SchedulingContainer>

              )
            })
          }

        </SchedulingContentContainer>



      </ContentContainer>

      <WrapModal>
        {showModal && (
          <Modal
            showModal
            closeModal={() => setShowModal(false)}
          >
            {
              schedulingDataModal()
            }
          </Modal>
        )}
      </WrapModal>

    </Container >
  )
}

export default SchedulingList