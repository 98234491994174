import React from 'react'

import * as Icons from "react-icons/fa"

function CustomIcon(props) {

  const FaIcon = Icons[props.name]

  if (!FaIcon) return <p>Icon not found!</p>

  return <FaIcon color={props.color} />
}

export default CustomIcon