import React, { createContext, useState, useEffect } from 'react'

import api from '../services/api'

export const CustomerContext = createContext({})

function CustomerProvider({ children }) {

  const [customer, setCustomer] = useState(JSON.parse(localStorage.getItem("customer")) || {})

  useEffect(() => {
    localStorage.setItem('customer', JSON.stringify(customer))
  }, [customer])

  const getCustomer = async (data) => {

    try {

      const response = await api.post('customer', data)

      setCustomer(response.data)

      return response?.data?.id ? true : false

    } catch (error) {      
      console.log(error.message)
      return false
    }

  }

  const registerCustomer = async (data, business_id) => {

    try {

      const response = await api.post(`customers_local/businesses/${business_id}`, data)

      if(response.data.id) {
        setCustomer(response.data)
      } 

    } catch (error) {
      alert(error.message)
      console.log(error.message)
    }

  }

  return (
    <CustomerContext.Provider value={{ customer, getCustomer, registerCustomer }}>
      {children}
    </CustomerContext.Provider>
  )
}

export default CustomerProvider