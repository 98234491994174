import styled from 'styled-components'

import { FaWindowClose, FaChevronDown } from "react-icons/fa"

import Modal from 'react-modal'

export const Container = styled.div`
  width: 85%;
  height: 50px;
  padding-left: 16px;
  padding-right: 16px;  
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(${({ theme }) => theme.colors.tertiaryBackgroundRGB}, 0.6);
  border: 0.1px solid rgba(${({ theme }) => theme.colors.secondaryTextRGB}, 0.7);
  border-radius: 3px;
  color: ${({ theme }) => theme.colors.secondaryText};
  font-size: 14px;
`;

// MODAL

export const ModalContainer = styled(Modal)`
  width: 100%;
  height: 100%;   
`;

export const ModalContent = styled.div`
  width: 100%;
  height: 100%; 
  background-color: ${({ theme }) => theme.colors.secondaryText};   
  overflow-y: scroll;
`;

export const CloseButton = styled.button`
  width: 100%;
  border: none;
  display: flex;
  justify-content: start;
  background-color: ${({ theme }) => theme.colors.secondaryText};
  border-bottom: 0.1px solid rgba(${({ theme }) => theme.colors.secondaryBackgroundRGB}, 0.4);
`

export const CloseIcon = styled(FaWindowClose).attrs(({ theme }) => ({
  color: theme.colors.tertiaryBackground,
  size: 50,
}))`
  margin: 16px;   
`;

export const ArrowDown = styled(FaChevronDown).attrs(({ theme }) => ({
  color: theme.colors.secondaryText,
  size: 18,
}))``;


// CONTENT LIST


export const DataListContainer = styled.button`
  width: 100%;
  height: 50px;
  border: none;
  border-bottom: 0.1px solid rgba(${({ theme }) => theme.colors.secondaryBackgroundRGB}, 0.4);
  background-color: transparent;  

  &:last-child {
    margin-bottom: 100px; 
}
`