import styled from 'styled-components'

import { BiLockAlt, BiEnvelope } from 'react-icons/bi'
import { FaGoogle } from "react-icons/fa"

import ImageBackground from '../../assets/background-Salon.jpg'
import ImgLogo from '../../assets/logo.png'


export const Container = styled.div`
width: 100%;
height: 100vh;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
background-color: rgba(${({ theme }) => theme.colors.tertiaryBackgroundRGB}, 0.65);
align-items: center;      
border: 1px red;
padding: 0;
margin: 0;
`;

export const ImageContainer = styled.div`
width: 100%;
height: 100vh;
background-image: url(${ImageBackground});
background-size: cover;
align-items: center;      
border: 1px red;
padding: 0;
margin: 0;
`;

export const Logo = styled.div`
  width: 90%;
  height: 160px;
  padding: 8px;
  background-color: rgba(${({ theme }) => theme.colors.primaryTextRGB}, 0.7);
  background-image: url(${ImgLogo});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-origin: content-box;  
  border-radius: 6px;
  margin-bottom: 32px;
`


// INPUT

export const InputContainer = styled.div`
  width: 90%;
  height: 50px;
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  background-color: rgba(${({ theme }) => theme.colors.tertiaryBackgroundRGB}, 0.6);
  border: 0.1px solid rgba(${({ theme }) => theme.colors.primaryTextRGB}, 0.7);
  border-radius: 3px;
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  padding-left: 8px;  
  color: ${({ theme }) => theme.colors.primaryText};

  &:focus {
    outline-width: 0;
}

&::placeholder {
    color: rgba(${({ theme }) => theme.colors.primaryTextRGB}, 0.5);
    font-size: 14px;
  }
`;

export const EmailIcon = styled(BiEnvelope).attrs(({ theme }) => ({
  color: theme.colors.primaryText,
  size: 16
}))``;

export const PasswordIcon = styled(BiLockAlt).attrs(({ theme }) => ({
  color: theme.colors.primaryText,
  size: 16,
}))``;


// SUBMIT BUTTON

export const SubmitButton = styled.button`
  width: 90%;
  height: 50px;
  background-color: ${({ theme }) => theme.colors.primaryContrast};
  border: none;
  border-radius: 25px;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.primaryText};
  font-weight: bold;
  margin-top: 12px;
  margin-bottom: 8px;
`;

export const Link = styled.a`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.primaryText};
  text-decoration: none;
  margin-top: 16px;
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.colors.primaryText}; 
  margin-top: 16px;
`

export const GoogleButton = styled.button`
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: none;  
  background-color: ${({ theme }) => theme.colors.googleButton};
  border-radius: 50px;
`

export const GoogleIcon = styled(FaGoogle).attrs(({ theme }) => ({
  color: theme.colors.primaryText,
  size: 28,
}))`
margin-bottom: 4px;
`;

export const GoogleTitle = styled.p`
  color: ${({ theme }) => theme.colors.primaryText}; 
  margin: 0; 
  font-size: 14px;
`