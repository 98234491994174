import React, { useState, useEffect } from 'react'

import Modal from 'react-modal'

import {
  Container,
  ModalContainer,
  CloseIcon,
  ModalContent,
  ArrowDown,
  DataListContainer,
  CloseButton
} from './styles'

Modal.setAppElement('#root')

function InputSelect(props) {

  const [showModal, setShowModal] = useState(false)

  function handleSelectData (item) {
    props.selected(item)
    closeModal()
  }

  function closeModal() {
    setShowModal(false)
  }

  return (
    <>
      <Container onClick={() => setShowModal(true)}>
        {props.placeholder}
        <ArrowDown />
      </Container>

      {
        showModal && (
          <ModalContainer
            isOpen={showModal}
            onRequestClose={closeModal}
            shouldCloseOnEsc={true}
          >
            <CloseButton onClick={closeModal}>
              <CloseIcon />
            </CloseButton>



            <ModalContent>
              {
                props.dataList.map((item, index) => {
                  return (
                    <DataListContainer key={index} onClick={() => handleSelectData(item)}>{item}</DataListContainer>
                  )
                })
              }
            </ModalContent>
          </ModalContainer>
        )}
    </>


  )
}

export default InputSelect